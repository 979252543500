'use strict';

const storedTheme = localStorage.getItem('theme');

const colorThemes = document.querySelectorAll('[name="theme"]');

const shine = document.querySelector('#shine');
const themeToggle = document.querySelector('.themes');

const lightRadio = document.querySelector('#light');
const darkRadio = document.querySelector('#dark');

if(storedTheme) document.documentElement.className = storedTheme;
if(storedTheme === 'light') {
    lightRadio.checked = true;
    darkRadio.checked = false;
} else if(storedTheme === 'dark')  {
    darkRadio.checked = true;
    lightRadio.checked = false;
}

colorThemes.forEach((themeOption) => {
    themeOption.addEventListener('change', () => {
        if(localStorage.getItem('consent') === 'true') localStorage.setItem('theme', themeOption.id);
        document.documentElement.className = themeOption.id;
        toggleLight(themeOption.id);
    });
});


function toggleLight(theme) {
    shine.classList = '';
    shine.style.opacity = '1';
    shine.classList.add('shine-animation');
    setTimeout(function() {
        shine.classList.remove('shine-animation');
        if(theme === 'light') {
            shine.classList.add('shine-hidden');
        }
    }, 500);
}